import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import ColorDots from "../components/ColorDots/ColorDots"
import EmailSignup from "../components/EmailSignup/EmailSignup"
import Footer from "../components/Footer/Footer"
import TextInput from "../components/TextInput/TextInput"
import Button from "../components/Button/Button"
import Link from "../components/Link/Link"
import styles from "./contactStyles.module.scss"
import Spinner from "../components/Spinner/Spinner"

const ContactForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useState(null)

  const handleSubmit = async d => {
    const response = await fetch(
      "https://api-dot-kindbio-605c7.uc.r.appspot.com/api/contactFormEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(d),
      }
    )

    return response
  }

  useEffect(() => {
    let subscribed = true
    if (loading) {
      handleSubmit({ name, email, subject, message }).then(response => {
        if (subscribed) {
          if (response.status === 200) {
            setName("")
            setSubject("")
            setEmail("")
            setMessage("")
            setLoading(false)
            setStatusMessage("Thanks! Your message has been received")
            setTimeout(() => {
              setStatusMessage(null)
            }, 2000)
          } else {
            setLoading(false)
            setStatusMessage("There was an error. Please try again later.")
            setTimeout(() => {
              setStatusMessage(null)
            }, 2000)
          }
        }
      })
    }
    return () => {
      subscribed = false
    }
  }, [loading])

  return (
    <div style={{ padding: "0 50px" }}>
      <div>Your Name</div>
      <TextInput
        fluid
        className="mt2"
        leftAlign
        value={name}
        onChange={v => setName(v)}
      ></TextInput>
      <div className="mt3">Your Email</div>
      <TextInput
        fluid
        className="mt2"
        leftAlign
        value={email}
        onChange={v => setEmail(v)}
      ></TextInput>
      <div className="mt3">Subject</div>
      <TextInput
        fluid
        className="mt2"
        leftAlign
        value={subject}
        onChange={v => setSubject(v)}
      ></TextInput>
      <div className="mt3">Message</div>
      <textarea
        className="mt2"
        rows={5}
        style={{ width: "100%" }}
        value={message}
        onChange={e => {
          setMessage(e.currentTarget.value)
        }}
      ></textarea>
      <Button
        className="mt3"
        onClick={() => {
          setLoading(true)
        }}
        disabled={loading}
      >
        {loading ? <Spinner /> : "SUBMIT"}
      </Button>
      {statusMessage ? (
        <div style={{ marginTop: "10px", position: "absolute" }}>
          {statusMessage}
        </div>
      ) : null}
    </div>
  )
}

const Contact = () => {
  return (
    <Layout>
      <div>
        <h1 className="centered" style={{ margin: "100px auto 40px auto" }}>
          Contact Us
        </h1>
        <ColorDots style={{ margin: "40px auto" }}></ColorDots>
        <div className={styles.bodyWrapper}>
          <div style={{ flex: "70%" }}>
            <ContactForm></ContactForm>
          </div>
          <div style={{ flex: "30%" }} className={styles.linksWrapper}>
            <h3>For Product Sales</h3>
            <div style={{ marginTop: "10px" }}>
              <Link to="mailto:wholesale@kindbio.com">
                wholesale@kindbio.com
              </Link>
            </div>
            <h3 style={{ marginTop: "30px" }}>All Other Inquiries</h3>
            <div style={{ marginTop: "10px" }}>
              <Link to="mailto:hello@kindbio.com">hello@kindbio.com</Link>
            </div>
          </div>
        </div>
        <EmailSignup></EmailSignup>
        <Footer></Footer>
      </div>
    </Layout>
  )
}

export default Contact
